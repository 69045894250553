import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import ISO from '../../images/ISOTransparent.png'
import REACH from '../../images/Reach.png'
import Success from '../../images/success.png'
import Mission from '../../images/mission.png'
import { Helmet } from 'react-helmet'
import Partners from '../../components/Partners/Partners'

const ОНас = () => (
  <Layout>
    <Helmet>
      <title></title>
    </Helmet>
    <Header styleName="header" title="О компании" />
    <div className="content">
      <div className="columns content-row">
        <div className="column content-text">
          <div className="title-small content-title">Опыт и производство</div>
          ЗАО «Inžinerinė Ekologija» - специализированная инженерно-химическая
          компания, основанная опытными специалистами и технологами. Компания
          разрабатывает и реализует программы коррекционной обработки для
          технологических процессов на предприятиях различных отраслей
          промышленности.
          <br />
          <br />
          Наши программы IN-ECO<sup>®</sup> - это индивидуальный подход к
          химической обработке воды и технологических сред для оптимального
          решения вопросов коррозии, накипеобразования, микробиологического
          обрастания, вспенивания и других проблем, нарушающих производственный
          процесс на предприятиях.
          <br />
          <br />
          Сфера деятельности компании включает химико-технологические процессы
          водоподготовки и очистки воды, газа и нефтедобычи, переработке сахара,
          производства бумаги и т.д.
          <br />
          <br />
          Качество продукции IN-ECO<sup>®</sup> имеет для нас первостепенное
          значение. Наш производственный процесс сертифицирован по стандартам
          ISO 9001 и ISO 14001, мы соответствуем и выполняем все регламенты
          REACH, обеспечиваем безопасность труда и охрану окружающей среды.
        </div>
        <div className="column content-img">
          <img src={ISO} className="iso-img" style={{ width: '50%' }} alt="" />
          <img src={REACH} className="iso-img" alt="" />
        </div>
      </div>
      <div className="columns content-row">
        <div className="column content-img">
          <img src={Mission} alt="" />
        </div>
        <div className="column content-text">
          <div className="title-small content-title">Миссия</div>
          Завоевание репутации надежного производителя и поставщика качественной
          химической продукции, разработанной с использованием современных
          технологий и отвечающая высоким требованиям потребителя.
          <br />
          <br />
          Завоевание статуса надежного партнера в области предоставления
          инновационных инженерных решений, направленных на оптимизацию основных
          производственных процессов.
        </div>
      </div>
      <div className="columns content-row">
        <div className="column content-text">
          <div className="title-small content-title">Принципы успеха</div>
          <ul>
            <li className="content-text">
              Опытная команда инженеров-химиков с профессиональными знаниями в
              области технологических процессов.
            </li>
            <li className="content-text">
              Индивидуальный подход к каждому заказчику, глубокий анализ работы
              системы и особенностей процесса.
            </li>
            <li className="content-text">
              Регулярная связь с заказчиками и техническое сопровождение
              обеспечивают постоянное наблюдение и своевременные корректировки
              программ для гарантированной безупречной работы промышленного
              процесса.
            </li>
          </ul>
        </div>
        <div className="column content-img">
          <img src={Success} alt="" />
        </div>
      </div>
      <div className="title-small content-title">Наши партнеры</div>
      <Partners />
    </div>
  </Layout>
)

export default ОНас
